import React, { useState } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/HalvaCashbackNaVse10"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { CashbackHalva } from "../../components/CashbackHalva"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageData } from "../../interfaces/pageProps"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { BottomCta, RefinSeoBgImg } from "../../components/BottomCta"
import { Reviews } from "../../components/Reviews"
import { Questions } from "../../components/Questions"
import { QUESTIONS_FOR_HALVA_PKW } from "../../components/Questions/helpers"

const bannerTitle = (
  <>
    10% во всех
    <br className="d-ld-none" /> категориях
  </>
)

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerSubTitle = (
  <>
    в сотнях тысяч магазинов
    <br className="d-ld-none" /> и на маркетплейсах
  </>
)

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

const TITLE =
  "Карта Халва с кэшбэком до 10% | Рассрочка до 24 месяцев | 17% на остаток | Выпуск Бесплатно"

const DESCR =
  "Оформить карту Халва с кешбэком онлайн. Получите рассрочку на 24 месяца на миллионы товаров. Кэшбэк до 10% и 15% на остаток."

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [longForm] = useState(false)

  return (
    <Layout noHeader noFooter title={TITLE} description={DESCR} noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank />
      <Banner title={bannerTitle} description={bannerSubTitle} orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        progressBar
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
      />
      <NewCalculator orderNum="4" />
      <HowInstallmentWorks
        headTitle="Как оформить карту рассрочки «Халва»"
        variant="rassrochkaSHalvoi"
        orderNum="5"
      />
      <CashbackHalva orderNum="6" />
      <Partners
        subTitle="Совершайте покупки в магазинах-партнерах и получайте до 24 месяцев рассрочки"
        orderNum="7"
      />
      <BottomCta
        buttonText="Заказать карту"
        variant="pkwRedesign"
        title={GIFT_TITLE}
        BgImg={RefinSeoBgImg}
        orderNum="8"
      />
      <Reviews additionalEventInDataLayer orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-s-kehshbehkom/media/") {
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
